import React, { useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';

const Menu = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer, membership }) => {
  const history = useHistory();

  const checkInvalid = useCallback(() => {
    return false;
  }, []);

  const submit = useCallback(async () => {
  }, []);

  const handleRenewalClick = () => {
    if (membership.length > 0 || my_customer.status !== constClass.STATUS.UPD) {
      history.push(`/${siteId}/?page=${constClass.RENEWAL}`);
    }
  };

  useEffect(() => {
    setBack({
      title: '戻る',
      render: false,
      disabled: true,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '登録',
      render: false,
      disabled: true,
      onClick: submit
    });
  }, [history, setBack, setNext, siteId, checkInvalid, submit])

  return (
    <div id="menu" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-3">
        <div>
          <div className="row mx-0 mt-3 mb-3 text-center">
            <div className="col px-0">
              <Link to={`/${siteId}/?page=${constClass.REQUEST}`}>
                <button
                  className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                  <h4 className="mb-0 font-weight-bold">グッズ・チケット注文</h4>
                </button>
              </Link>
            </div>
          </div>
          <div className="row mx-0 mt-3 mb-3 text-center">
            <div className="col px-0">
              <Link to={`/${siteId}/?page=${constClass.HISTORY}`}>
                <button
                  className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                  <h4 className="mb-0 font-weight-bold">注文履歴</h4>
                </button>
              </Link>
            </div>
          </div>
          <div className="row mx-0 mt-3 mb-3 text-center">
            <div className="col px-0">
              <Link to={`/${siteId}/?page=${constClass.USER}`}>
                <button
                  className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                  <h4 className="mb-0 font-weight-bold">会員情報確認・変更</h4>
                </button>
              </Link>
            </div>
          </div>
          {my_customer.non_ticket_flag !== constClass.FLAG.ON &&
            <React.Fragment>
              <div className="row mx-0 mt-3 mb-0 text-center">
                <div className="col px-0">
                  <button
                    className={`btn btn-enable w-100 h-100 py-2 px-2`}
                    disabled={membership.length <= 0 || my_customer.status !== constClass.STATUS.UPD}
                    onClick={handleRenewalClick}
                  >
                    <h4 className="mb-0 font-weight-bold">更新申込</h4>
                  </button>
                </div>
              </div>
              {(setting['MENU_RENEWAL_NOTICE']) &&
                <div className="row mx-0 mt-1 mb-2 text-center">
                  <div className="col px-0">
                    {Common.getBrString(setting['MENU_RENEWAL_NOTICE'])}
                  </div>
                </div>
              }
            </React.Fragment>
          }
          {(setting['MENU_NOTICE']) &&
            <div className="row mx-0 mt-1 mb-3 text-center">
              <div className="col px-0">
                {Common.getBrString(setting['MENU_NOTICE'])}
              </div>
            </div>
          }
          <div className="row mx-0 my-1 text-center">
            <div className="col px-0">
              <Link to={`/${siteId}/?page=${constClass.SPECIFIEDCOMMERCIAL}`}><u>特定商取引法に関する表記</u></Link>
            </div>
          </div>
          <div className="row mx-0 my-3 text-center">
            <div className="col px-0">
              <Link to={`/${siteId}/?page=${constClass.GUIDE}`}><u>ご利用案内</u></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu;