import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import { useParams } from 'react-router';
import { JobCdName } from '../Payment/Payment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import { useToasts } from 'react-toast-notifications';
registerLocale('ja', ja);

const PaymentHistory = (props) => {
  const { siteId } = useParams();
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [condition, setCondition] = useState({ search_from: moment().date(1).toDate(), search_to: moment().add(1, 'month').date(1).add(-1, 'day').toDate(), customer_no: '' });
  const [searchFlag, setSearchFlag] = useState(true);
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [sortOrder, setSortOrder] = useState([]);
  const { addToast } = useToasts();

  const getIdObject = (id) => {
    const ary = id.split('_');
    if (ary.length === 3) {
      return {
        membership_year: ary[0],
        customer_no: ary[1],
        customer_id: ary[2]
      };
    } else {
      return {
        order_id: id
      };
    }
  }

  const getCustomer = useCallback((id, customer) => {
    return customer.find(c => c.customer_id === Number(getIdObject(id).customer_id)) ? customer.find(c => c.customer_id === Number(getIdObject(id).customer_id)) : {}
  }, [])

  const getOrder = useCallback((id, order) => {
    return order.find(o => o.order_id === Number(id)) ? order.find(o => o.order_id === Number(id)) : {}
  }, [])

  const sortData = useCallback((a, b) => {
    if (sortOrder.length <= 0) { // 初期値は決済日時
      return (a.upd_date < b.upd_date) ? -1 : 1;
    }
    for (var i = 0; i < sortOrder.length; i++) {
      var item = sortOrder[i];
      if (item.column === 'customer_no') {
        const aValue = getIdObject(a.id).customer_no ? getIdObject(a.id).customer_no : getOrder(a.id, orderData).customer_no;
        const bValue = getIdObject(b.id).customer_no ? getIdObject(b.id).customer_no : getOrder(b.id, orderData).customer_no;
        if (item.order === 'ASC') {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        } else {
          if (bValue < aValue) return -1;
          if (bValue > aValue) return 1;
        }
      } else if (item.column === 'customer_name') {
        const aValue = getIdObject(a.id).customer_id ? getCustomer(a.id, customerData).customer_name : getOrder(a.id, orderData).customer_name;
        const bValue = getIdObject(b.id).customer_id ? getCustomer(b.id, customerData).customer_name : getOrder(b.id, orderData).customer_name;
        if (item.order === 'ASC') {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        } else {
          if (bValue < aValue) return -1;
          if (bValue > aValue) return 1;
        }
      } else if (item.column === 'receipt_num') {
        const aValue = getIdObject(a.id).customer_id ? "" : getOrder(a.id, orderData).receipt_num;
        const bValue = getIdObject(b.id).customer_id ? "" : getOrder(b.id, orderData).receipt_num;
        if (item.order === 'ASC') {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        } else {
          if (bValue < aValue) return -1;
          if (bValue > aValue) return 1;
        }
      } else if (item.column === 'amount') {
        const aValue = Number(a.amount) + Number(a.fee);
        const bValue = Number(b.amount) + Number(b.fee);
        if (item.order === 'ASC') {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        } else {
          if (bValue < aValue) return -1;
          if (bValue > aValue) return 1;
        }
      } else if (item.column === 'job_cd') {
        const jobCds = Object.keys(JobCdName);
        const aValue = jobCds.indexOf(a.job_cd);
        const bValue = jobCds.indexOf(b.job_cd);
        if (item.order === 'ASC') {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        } else {
          if (bValue < aValue) return -1;
          if (bValue > aValue) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [sortOrder, customerData, getCustomer, getOrder, orderData]);

  const createCsvData = useCallback((data, customer_data, order_data) => {
    const list = [];
    const head = [];
    head.push({ label: "決済日時", key: "upd_date" });
    head.push({ label: "会員番号", key: "customer_no" });
    head.push({ label: "お名前", key: "customer_name" });
    head.push({ label: "注文番号", key: "receipt_num" });
    head.push({ label: "決済金額", key: "total" });
    head.push({ label: "決済状況", key: "job_cd_name" });
    head.push({ label: "決済ID", key: "transaction_id" });
    data.forEach(d => {
      const id = getIdObject(d.id);
      const record = {
        ...d,
        upd_date: moment(d.upd_date).format('YYYY/MM/DD HH:mm:ss'),
        customer_no: id.customer_no ? id.customer_no : getOrder(id.order_id, order_data).customer_no,
        customer_name: id.customer_id ? getCustomer(d.id, customer_data).customer_name : getOrder(id.order_id, order_data).customer_name,
        receipt_num: id.customer_id ? '' : getOrder(id.order_id, order_data).receipt_num,
        total: (Number(d.amount) + Number(d.fee)),
        job_cd_name: JobCdName[d.job_cd],
        transaction_id: d.actual_id
      };
      list.push(record);
    });
    setCsvData({ header: head, list: list });
  }, [getCustomer, getOrder]);

  const refreshCustomer = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    // useCallback対応のため検索ボタンを押したときのみ実行
    if (searchFlag) {
      const reg_params = {
        site_id: siteId,
        insert_start_date: moment(condition.search_from).startOf('day'),
        insert_end_date: moment(condition.search_to).endOf('day'),
        customer_no: condition.customer_no,
      }
      // お客様データ
      setLockData(true);
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/payment_history/`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setPaymentHistory(data.payment);

      // 決済データを会費と注文に分割する
      const cIds = [];
      const oIds = [];
      data.payment.forEach(p => {
        const id = getIdObject(p.id);
        if (id.customer_id) {
          cIds.push(id.customer_id);
        }
        if (id.order_id) {
          oIds.push(id.order_id);
        }
      });

      // 会費の会員情報を取得する
      // const ids = data.payment.map(p => getIdObject(p.id).customer_id);
      var customer_data = [];
      if (cIds.length > 0) {
        const search_params = {
          site_id: siteId,
          customer_id: cIds
        }
        customer_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, search_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
      }
      setCustomerData(customer_data);
      // 注文情報を取得する
      var order_data = [];
      if (oIds.length > 0) {
        const search_params = {
          site_id: siteId,
          order_id: oIds
        }
        order_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/detail/`, search_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
      }
      setOrderData(order_data);
      createCsvData(data.payment, customer_data, order_data);
      setLockData(false);
    }
    setSearchFlag(false);
  }, [searchFlag, siteId, condition, createCsvData]);

  const handleChangeCondition = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setCondition({ ...condition, [name]: value });
  }

  const handleChangeDate = (name, value) => {
    setCondition({ ...condition, [name]: value });
  }

  const handleRefundClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    // ボタン非活性時は即時終了
    if (lockData) {
      return;
    }

    // 非売上状態のデータは返金不可
    if (!['AUTH', 'SALES'].includes(data.job_cd)) {
      return;
    }

    if (!window.confirm('返金してもよろしいですか？' +
      '\n会員番号：' + (getIdObject(data.id).customer_no ? getIdObject(data.id).customer_no : getOrder(data.id, orderData).customer_no) +
      (getIdObject(data.id).customer_id ? '' : ('\n注文番号：' + getOrder(data.id, orderData).receipt_num)) +
      '\n決済金額：' + (Number(data.amount) + Number(data.fee)).toLocaleString() + ' 円' +
      '\n決済ID：' + data.actual_id)) {
      return;
    }

    // 送信用データ作成
    const reg_params = {
      site_id: siteId,
      id: data.id
    }

    // API実行
    setLockData(true);
    try {
      const ret = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/refund/`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (ret.data && ret.data.status === 'success') {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      } else {
        if (ret.data && ret.data.message) {
          addToast(ret.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('システムエラーが発生しました。', { appearance: 'error', autoDismiss: true });
        }
      }
      setSearchFlag(true);
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  const handleRefundRegClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    // ボタン非活性時は即時終了
    if (lockData) {
      return;
    }

    // 非売上状態のデータは返金不可
    if (!['AUTH', 'SALES'].includes(data.job_cd)) {
      return;
    }

    if (!window.confirm('この操作では返金されません。この決済を既に返金済みとして登録します。よろしいですか？' +
      '\n会員番号：' + (getIdObject(data.id).customer_no ? getIdObject(data.id).customer_no : getOrder(data.id, orderData).customer_no) +
      (getIdObject(data.id).customer_id ? '' : ('\n注文番号：' + getOrder(data.id, orderData).receipt_num)) +
      '\n決済金額：' + (Number(data.amount) + Number(data.fee)).toLocaleString() + ' 円' +
      '\n決済ID：' + data.actual_id)) {
      return;
    }

    // 送信用データ作成
    const reg_params = {
      site_id: siteId,
      id: data.id
    }

    // API実行
    setLockData(true);
    try {
      const ret = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/refund_register/`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (ret.data && ret.data.status === 'success') {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      } else {
        if (ret.data && ret.data.message) {
          addToast(ret.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('システムエラーが発生しました。', { appearance: 'error', autoDismiss: true });
        }
      }
      setSearchFlag(true);
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  // ヘッダーソートイベント
  const handleHeaderClick = (column) => {
    var _sortOrder = JSON.parse(JSON.stringify(sortOrder));
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    setSortOrder(_sortOrder);
    // var data = orderData.slice();
    // data.sort((a, b) => sortData(a, b));
    // setOrderData(data);
  }

  const handleChangeRefund = (id) => (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked) : target.value;

    const _paymentHistory = [...paymentHistory];
    const payment = _paymentHistory.find(p => p.id === id);
    payment.refund = value;
    setPaymentHistory(_paymentHistory);
  }

  useEffect(() => {
    refreshCustomer();
  }, [refreshCustomer]);

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)}>
      {sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  return (
    <div className="container">
      <div className="row mx-0 my-2 px-1-env">
        <div className="col">
          <div className="row my-1">
            <div className="col-8">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>検索期間</span>
                </div>
                <div className="col text-center">
                  <DatePicker
                    className="form-control w-100"
                    aria_labal="検索期間（開始）"
                    locale="ja"
                    dateFormat="yyyy/MM/dd"
                    id="search_from"
                    name="search_from"
                    selected={condition.search_from}
                    onChange={date => handleChangeDate("search_from", date)}
                    showMonthDropdown
                    autoComplete={"off"}
                    disabled={lockData} />
                </div>
                <div className="col-auto text-center align-self-center">
                  <span>～</span>
                </div>
                <div className="col text-center">
                  <DatePicker
                    className="form-control w-100"
                    aria_labal="検索期間（終了）"
                    locale="ja"
                    dateFormat="yyyy/MM/dd"
                    id="search_to"
                    name="search_to"
                    selected={condition.search_to}
                    onChange={date => handleChangeDate("search_to", date)}
                    showMonthDropdown
                    autoComplete={"off"}
                    disabled={lockData} />
                </div>
                <div className="col-auto text-center align-self-center">
                  <span>会員番号</span>
                </div>
                <div className="col text-center">
                  <input className={`form-control`} type="text" id="customer_no" name="customer_no" value={condition.customer_no} onChange={handleChangeCondition}></input>
                </div>
              </div>
            </div>
            <div className="col-1 text-center">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                disabled={lockData}
                onClick={() => setSearchFlag(true)}>
                検索</button>
            </div>
          </div>
        </div>
      </div>
      {(paymentHistory === null || customerData === null || orderData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {(paymentHistory !== null && customerData !== null && orderData !== null) && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-10 pl-0 text-left align-self-end">
              <div className="row mr-0">
                <div className="col-2 pr-2 align-self-center">
                  {paymentHistory[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          一覧表印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 align-self-center">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list.sort((a, b) => sortData(a, b))} headers={csvData.header} filename={"決済履歴.csv"}>
                      CSV出力
                    </CSVLink>
                  }
                </div>
              </div>
            </div>
            <div className="col-2 px-2 align-self-center text-right border h-100">
              <div className="row">
                <div className="col-auto py-1 px-2 bg-lightwhite h-100">一覧表件数</div>
                <div className="col py-1">{paymentHistory.length.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-info`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('upd_date')}>
                      決済日時
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('customer_no')}>
                      会員番号
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('customer_name')}>
                      お名前
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('receipt_num')}>
                      注文番号
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('amount')}>
                      決済金額
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('job_cd')}>
                      決済状況
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('actual_id')}>
                      決済ID
                    </th>
                    <th className="text-center align-middle text-nowrap">
                      処理
                    </th>
                  </tr>
                  <tr className="print-none">
                    {renderSortTh('upd_date')}
                    {renderSortTh('customer_no')}
                    {renderSortTh('customer_name')}
                    {renderSortTh('receipt_num')}
                    {renderSortTh('amount')}
                    {renderSortTh('job_cd')}
                    {renderSortTh('actual_id')}
                    <th className="text-center align-middle print-none">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistory.sort((a, b) => sortData(a, b)).map(record => (
                    <tr key={record.id}>
                      {/* 決済日時 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {moment(record.upd_date).format('YYYY/MM/DD HH:mm:ss')}
                      </td>
                      {/* 会員番号 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {getIdObject(record.id).customer_no ? getIdObject(record.id).customer_no : getOrder(record.id, orderData).customer_no}
                      </td>
                      {/* お名前 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {getIdObject(record.id).customer_id ? getCustomer(record.id, customerData).customer_name : getOrder(record.id, orderData).customer_name}
                      </td>
                      {/* 注文番号 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {getIdObject(record.id).customer_id ? "" : getOrder(record.id, orderData).receipt_num}
                      </td>
                      {/* 決済金額 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {(Number(record.amount) + Number(record.fee)).toLocaleString()} 円
                      </td>
                      {/* 決済状況 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {JobCdName[record.job_cd]}
                      </td>
                      {/* 決済ID */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {record.actual_id}
                      </td>
                      {/* 処理 */}
                      <td className={'text-center align-middle text-nowrap'}>
                        {['AUTH', 'SALES'].includes(record.job_cd) &&
                          <React.Fragment>
                            <button type="button"
                              disabled={lockData}
                              className={`btn btn-secondary mx-1`}
                              onClick={() => handleRefundClick(record)}>
                              返金
                            </button>
                            <input type="checkbox" className="ml-1" name={`check_${record.id}`} checked={record.refund || false} onChange={handleChangeRefund(record.id)}></input>
                            {record.refund &&
                              <button type="button"
                                disabled={lockData}
                                className={`btn btn-secondary mx-1`}
                                onClick={() => handleRefundRegClick(record)}>
                                返金済登録
                              </button>
                            }
                          </React.Fragment>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentHistory;