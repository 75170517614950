import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useHistory, useParams } from 'react-router';
import Common from '../Common/common';
import { useToasts } from 'react-toast-notifications';

const CustomerDetail = (props) => {
  const { id, siteId } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const [errMsg, setErrMsg] = useState({});
  const [lockData, setLockData] = useState(false);
  const [changed, setChanged] = useState(false);
  const [revert, setRevert] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    try {
      var customer;
      if (id) {
        const reg_params = { site_id: siteId, customer_id: id };
        // お客様データ
        const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        customer = data.find(() => true);
      } else {
        customer = {
          site_id: siteId,
          customer_no: '',
          status: constClass.STATUS.REG,
          customer_kana_sei: '',
          customer_kana_mei: '',
          customer_name_sei: '',
          customer_name_mei: '',
          customer_postal_code: '',
          customer_address1: '',
          customer_address2: '',
          customer_address3: '',
          customer_tel: '',
          customer_mail: '',
          customer_birthday: '00000000',
          customer_job: '',
          method: '',
          refund_flag: constClass.FLAG.OFF,
          bank_name: '',
          branch_name: '',
          account_type: constClass.ACCOUNT_TYPE.SAVING,
          account_number: '',
          account_holder: '',
          vip_flag: constClass.FLAG.OFF,
          non_ticket_flag: constClass.FLAG.OFF,
          customer_type: ''
        };
      }
      setCustomerData(customer);
      setChanged(false);
      setRevert(false);
    } catch (err) {
      console.log(err);
    }
  }, [siteId, id]);

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setCustomerData({ ...customerData, [name]: value });
    // setErrMsg({ ...errMsg, [name]: validator(name, value) });
    setChanged(true);
  }

  const handleBlur = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setErrMsg({ ...errMsg, [name]: validator(name, value) });
  }

  const validator = (name, value) => {
    switch (name) {
      case 'customer_kana_sei':
      case 'customer_kana_mei':
        return Common.kanaValidation(value, false);
      case 'customer_tel':
        return Common.mobileNoValidation(value, false);
      case 'customer_mail':
        return Common.mailAddressValidation(value, false);
      case 'customer_postal_code':
        return Common.postalCodeValidation(value, false);
      case 'customer_birthday':
        return Common.dateValidation(value, false, true, true, true);
      case 'account_number':
        return Common.numericValidation(value, false);
      default:
        return '';
    }
  }

  const checkInvalid = () => {
    var ret = false;

    // エラーメッセージのチェック
    Object.keys(errMsg).forEach(name => {
      if (errMsg[name]) {
        ret = true;
      }
    });
    return ret;
  }

  const searchAddress = async () => {
    const jwt = localStorage.getItem('jwt');
    // API実行
    setLockData(true);
    try {
      const result = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/address/${customerData.customer_postal_code}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      if (result) {
        var address = '';
        var address2 = '';
        if (result.PrefecturesName) {
          address += result.PrefecturesName;
        }
        if (result.CityName) {
          address += result.CityName;
        }
        if (result.TownName) {
          address2 += result.TownName;
        }
        setCustomerData({ ...customerData, customer_address1: address, customer_address2: address2, customer_address3: '' });
        setChanged(true);
      } else {
        window.alert('郵便番号が見つかりません');
      }
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  const handleRegister = async () => {
    const jwt = localStorage.getItem('jwt');
    // ボタン非活性時は即時終了
    if (checkInvalid() || lockData) {
      return;
    }

    // 各項目のvalidatorを実行してエラーが無いかチェック
    const invalid = Object.keys(customerData).reduce((prev, name) => prev + validator(name, customerData[name]), '');
    if (invalid) {
      return;
    }

    // ステータスが有効な場合は会員番号が必須
    if ([constClass.STATUS.REG, constClass.STATUS.UPD].includes(customerData.status) && !customerData.customer_no) {
      alert('有効な会員の場合は必ず会員番号を登録してください。');
      return;
    }

    // その他必須項目チェック（未入力時に警告表示のみ）
    var errors = [];
    if (!customerData.customer_no) {
      errors.push('会員番号');
    }
    if (!customerData.customer_kana_sei) {
      errors.push('お名前（カナ） セイ');
    }
    if (!customerData.customer_kana_mei) {
      errors.push('お名前（カナ） メイ');
    }
    if (!customerData.customer_name_sei) {
      errors.push('お名前 姓');
    }
    if (!customerData.customer_name_mei) {
      errors.push('お名前 名');
    }
    if (!customerData.customer_postal_code) {
      errors.push('郵便番号');
    }
    if (!customerData.customer_address1) {
      errors.push('住所1');
    }
    if (!customerData.customer_address2) {
      errors.push('住所2');
    }
    if (!customerData.customer_tel) {
      errors.push('携帯番号');
    }
    if (!customerData.customer_mail) {
      errors.push('メールアドレス');
    }
    const birth = Common.getDateObject(customerData.customer_birthday);
    if (!birth.month) {
      errors.push('お誕生日 月');
    } if (!birth.date) {
      errors.push('お誕生日 日');
    }
    // if (customerData.refund_flag !== constClass.FLAG.ON) {
    if (!customerData.bank_name) {
      errors.push('返金口座 銀行名');
    }
    if (!customerData.branch_name) {
      errors.push('返金口座 支店名');
    }
    if (!customerData.account_number) {
      errors.push('口座番号');
    }
    if (!customerData.account_holder) {
      errors.push('口座名義');
    }
    // }
    if (errors.length > 0) {
      if (!window.confirm(`必須項目に未入力の項目があります。このまま登録しますか？\n${errors.join('、')}`)) {
        return;
      }
    }

    // 送信用データ作成
    const reg_params = {
      ...customerData
    }
    // 不要なプロパティを削除
    delete reg_params.ins_name;
    delete reg_params.ins_date;
    delete reg_params.upd_name;
    delete reg_params.upd_date;

    // API実行
    setLockData(true);
    try {
      if (id) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/customer/${id}`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
      }
      await refreshData();
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  const handleStatusChange = async (status) => {
    const jwt = localStorage.getItem('jwt');
    // ボタン非活性時は即時終了
    if (checkInvalid() || changed || lockData) {
      return;
    }

    // 新規登録の場合
    if (customerData.status === constClass.STATUS.TMP && status === constClass.STATUS.REG) {
      if (!customerData.customer_no) {
        alert('先に会員番号を登録してください。');
        return;
      } else if (!window.confirm('登録済みに変更してもよろしいですか？\n会員番号：' + customerData.customer_no)) {
        return;
      }
    }

    // 更新申込の場合
    if (customerData.status === constClass.STATUS.UPD && status === constClass.STATUS.REG) {
      if (!window.confirm('来年度の更新申込を登録してもよろしいですか？\n会員番号：' + customerData.customer_no)) {
        return;
      }
    }

    // 更新申込取消の場合
    if (customerData.status === constClass.STATUS.REG && status === constClass.STATUS.UPD) {
      if (!window.confirm('来年度の更新申込を取消してもよろしいですか？\n会員番号：' + customerData.customer_no)) {
        return;
      }
    }

    // 退会の場合
    if (status === constClass.STATUS.CCL) {
      if (!window.confirm('退会してもよろしいですか？\n会員番号：' + customerData.customer_no)) {
        return;
      }
    }

    // 退会取消の場合
    if (customerData.status === constClass.STATUS.CCL && status === constClass.STATUS.REG) {
      if (!window.confirm('退会を取消してもよろしいですか？\n会員番号：' + customerData.customer_no)) {
        return;
      }
    }

    // 送信用データ作成
    const reg_params = {
      site_id: siteId,
      status
    }

    // API実行
    setLockData(true);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/status/${id}`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      await refreshData();
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  const renderRequired = () => <span className={'text-danger'}>※</span>

  const renderText = (label, name, required = false, disabled = false, headerSize = 'col-3', prefix = null, suffix = null) => <div className="row my-2">
    <div className={`${headerSize} text-left align-self-center`}>
      {required && renderRequired()}<span>{label}</span>
    </div>
    <div className="col text-left align-self-center">
      <div className="row h-100">
        {prefix &&
          <div className="col-auto text-left align-self-center">
            {prefix}
          </div>
        }
        <div className="col text-left align-self-center">
          <input className={`form-control`} type="text" id={name} name={name} value={customerData[name] || ''} onChange={handleChange} onBlur={handleBlur} disabled={disabled}></input>
        </div>
        {suffix &&
          <div className="col-auto text-left align-self-center">
            {suffix}
          </div>
        }
      </div>
      {errMsg[name] &&
        <div className="row h-100">
          <div className="col text-left align-self-center text-danger">
            {errMsg[name]}
          </div>
        </div>
      }
    </div>
  </div>

  // const setYears = (from, to) => {
  //   const items = [];
  //   const nowYear = new Date().getFullYear();
  //   var range_from = from ? from : nowYear;
  //   var range_to = to ? to : nowYear;
  //   items.push(<option value="" key={"-"}>- 年 -</option>);
  //   if (range_from < range_to) {
  //     for (let i = range_from; i <= range_to; i++) {
  //       items.push(<option value={i} key={i}>{i}年</option>);
  //     }
  //   } else {
  //     for (let i = range_from; i >= range_to; i--) {
  //       items.push(<option value={i} key={i}>{i}年</option>);
  //     }
  //   }
  //   return items;
  // }
  const setMonth = () => {
    const items = [];
    items.push(<option value="" key={"-"}>- 月 -</option>)
    for (let i = 1; i <= 12; i++) {
      items.push(<option value={("00" + i).slice(-2)} key={i}>{i}月</option>)
    }
    return items;
  }
  const setDay = (value) => {
    const items = [];
    const year = value.slice(0, 4);
    const month = value.slice(4, 6);
    items.push(<option value="" key={"-"}>- 日 -</option>)
    for (let i = 1; i <= 31; i++) {
      if (!year || !Number(month) || (new Date(year + '/' + month + '/' + ("00" + i).slice(-2)).getDate() === i)) {
        items.push(<option value={("00" + i).slice(-2)} key={i}>{i}日</option>)
      }
    }
    return items;
  }
  const handleDateChange = (e, changeEvent) => {
    const target = e.target;
    // var temp = Number(document.getElementById(target.name + '_y') ? document.getElementById(target.name + '_y').value : 0) * 10000;
    var temp = 0;
    temp += Number(document.getElementById(target.name + '_m') ? document.getElementById(target.name + '_m').value : 0) * 100;
    temp += Number(document.getElementById(target.name + '_d') ? document.getElementById(target.name + '_d').value : 0);
    changeEvent({
      target: {
        type: 'text',
        name: target.name,
        value: ("00000000" + temp).slice(-8)
      }
    })
  }

  return (
    <div className="container">
      {(customerData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {customerData &&
        <div className="row mx-0 my-2 px-1-env">
          <div className="col">
            <div className="row my-1">
              <div className="col-auto text-left">
                <button type="button"
                  className={`btn btn-secondary mx-1 w-100`}
                  onClick={() => history.goBack()}>
                  戻る
                </button>
              </div>
              <div className="col">
                <div className="row my-2">
                  <div className="col-6">
                    {renderText('会員番号', 'customer_no', true, !!id && customerData.status !== constClass.STATUS.TMP)}
                  </div>
                  <div className="col-6">
                    <div className="row h-100">
                      <div className="col-3 text-left align-self-center">
                        {renderRequired()}<span>ステータス</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <select className="form-control text-left" id={'status'} name={'status'} onChange={handleChange} onBlur={handleBlur} value={customerData.status} disabled={id}>
                          <option value={constClass.STATUS.TMP}>{constClass.STATUS_NAME.TMP}</option>
                          <option value={constClass.STATUS.REG}>{constClass.STATUS_NAME.REG}</option>
                          <option value={constClass.STATUS.UPD}>{constClass.STATUS_NAME.UPD}</option>
                          <option value={constClass.STATUS.CCL}>{constClass.STATUS_NAME.CCL}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row my-2">
                      <div className="col-2 text-left align-self-center">
                        <span>会員種別</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row px-0 mx-0">
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id={'customer_type_paper'} name={'customer_type'} value={constClass.CUSTOMER_TYPE.PAPER} checked={customerData.customer_type === constClass.CUSTOMER_TYPE.PAPER} onChange={handleChange} onBlur={handleBlur}></input>
                          </div>
                          <div className="col-4 align-self-center">
                            <label className="my-0" htmlFor={'customer_type_paper'}>紙</label>
                          </div>
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id={'customer_type_line'} name={'customer_type'} value={constClass.CUSTOMER_TYPE.LINE} checked={customerData.customer_type === constClass.CUSTOMER_TYPE.LINE} onChange={handleChange} onBlur={handleBlur}></input>
                          </div>
                          <div className="col-4 align-self-center">
                            <label className="my-0" htmlFor={'customer_type_line'}>LINE</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row h-100">
                      <div className="col-2 text-left align-self-center">
                        {renderRequired()}<span>お名前(カナ)</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row">
                          <div className="col-6">
                            {renderText('セイ', 'customer_kana_sei', false, false)}
                          </div>
                          <div className="col-6">
                            {renderText('メイ', 'customer_kana_mei', false, false)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row h-100">
                      <div className="col-2 text-left align-self-center">
                        {renderRequired()}<span>お名前</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row">
                          <div className="col-6">
                            {renderText('姓', 'customer_name_sei', false, false)}
                          </div>
                          <div className="col-6">
                            {renderText('名', 'customer_name_mei', false, false)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-6">
                    {renderText('ご住所', 'customer_postal_code', true, false, 'col-3', '〒', <button type="button"
                      className={`btn btn-secondary mx-1 w-100`}
                      disabled={lockData}
                      onClick={() => searchAddress()}>
                      住所検索
                    </button>)}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('都道府県市区町村', 'customer_address1', true, false, 'col-2')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('町域・番地', 'customer_address2', true, false, 'col-2')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('建物名など', 'customer_address3', false, false, 'col-2')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('携帯番号', 'customer_tel', true, false, 'col-2', null, '（半角数字、半角ハイフン要）')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('メール', 'customer_mail', true, false, 'col-2')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row h-100">
                      <div className="col-2 text-left align-self-center">
                        {renderRequired()}<span>お誕生日</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row px-0 mx-0">
                          {/* <div className={`col-4 px-0 mx-0 align-self-center`}>
                            <select className="form-control text-center" id={'customer_birthday_y'} name={'customer_birthday'} onChange={(e) => handleDateChange(e, handleChange)} value={customerData.customer_birthday.slice(0, 4)}>
                              {setYears(1900, null)}
                            </select>
                          </div> */}
                          <div className={`col-4 px-1 mx-0 align-self-center`}>
                            <select className="form-control text-center" id={'customer_birthday_m'} name={'customer_birthday'} onChange={(e) => handleDateChange(e, handleChange)} value={customerData.customer_birthday.slice(4, 6)}>
                              {setMonth()}
                            </select>
                          </div>
                          <div className={`col-4 px-1 mx-0 align-self-center`}>
                            <select className="form-control text-center" id={'customer_birthday_d'} name={'customer_birthday'} onChange={(e) => handleDateChange(e, handleChange)} value={customerData.customer_birthday.slice(6, 8)}>
                              {setDay(customerData.customer_birthday)}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-2">
                  <div className="col-10">
                    {renderText('職種', 'customer_job', false, false, 'col-2')}
                  </div>
                </div> */}
                {/* <div className="row my-2">
                  <div className="col-10">
                    {renderText('入会方法', 'method', false, false, 'col-2')}
                  </div>
                </div> */}
                {/* <div className="row my-2">
                  <div className="col-10">
                    <div className="row my-2">
                      <div className="col-2 text-left align-self-center">
                        <span>返金について</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row px-0 mx-0">
                          <div className="col-2 align-self-center">
                            <input type="checkbox" className="form-control" id={'refund_flag'} name={'refund_flag'} value="true" checked={customerData.refund_flag === constClass.FLAG.ON} onChange={handleChange}></input>
                          </div>
                          <div className="col-10 align-self-center">
                            <label className="my-0" htmlFor={'refund_flag'}>返金不要</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row h-100">
                      <div className="col-2 text-left align-self-center">
                        {renderRequired()}<span>返金口座</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row">
                          <div className="col-6">
                            {renderText('', 'bank_name', false, false, 'd-none', null, <span className="mr-3">銀行</span>)}
                          </div>
                          <div className="col-6">
                            {renderText('', 'branch_name', false, false, 'd-none', null, <span>支店</span>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row h-100">
                      <div className="col-2 text-left align-self-center">
                        {renderRequired()}<span>口座番号</span>
                      </div>
                      <div className="col text-left align-self-center">
                        <div className="row">
                          <div className="col-4 align-self-center">
                            <select className="form-control text-left" id={'account_type'} name={'account_type'} onChange={handleChange} onBlur={handleBlur} value={customerData.account_type}>
                              <option value={constClass.ACCOUNT_TYPE.SAVING}>{constClass.ACCOUNT_TYPE_NAME.SAVING}</option>
                              <option value={constClass.ACCOUNT_TYPE.CURRENT}>{constClass.ACCOUNT_TYPE_NAME.CURRENT}</option>
                            </select>
                          </div>
                          <div className="col-8 align-self-center">
                            {renderText('', 'account_number', false, false, 'd-none')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('口座名義', 'account_holder', true, false, 'col-2')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    <div className="row my-2">
                      <div className="col-2 text-left align-self-center">
                        <span>会員フラグ</span>
                      </div>
                      <div className="col-4 text-left align-self-center">
                        <div className="row px-0 mx-0">
                          <div className="col-4 align-self-center">
                            <input type="checkbox" className="form-control" id={'vip_flag'} name={'vip_flag'} value="true" checked={customerData.vip_flag === constClass.FLAG.ON} onChange={handleChange} onBlur={handleBlur}></input>
                          </div>
                          <div className="col align-self-center">
                            <label className="my-0" htmlFor={'vip_flag'}>VIP会員</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 text-left align-self-center">
                        <div className="row px-0 mx-0">
                          <div className="col-4 align-self-center">
                            <input type="checkbox" className="form-control" id={'non_ticket_flag'} name={'non_ticket_flag'} value="true" checked={customerData.non_ticket_flag === constClass.FLAG.ON} onChange={handleChange} onBlur={handleBlur}></input>
                          </div>
                          <div className="col align-self-center align-left">
                            <label className="my-0" htmlFor={'non_ticket_flag'}>チケット不可</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-10">
                    {renderText('LINE ID', 'line_id', false, true, 'col-2')}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-3">
                    <button type="button"
                      className={`btn btn-primary mx-1 w-100`}
                      disabled={checkInvalid() || lockData}
                      onClick={() => handleRegister()}>
                      登録
                    </button>
                  </div>
                  {customerData.status === constClass.STATUS.TMP &&
                    <div className="col-3">
                      <button type="button"
                        className={`btn btn-primary mx-1 w-100`}
                        disabled={checkInvalid() || changed || lockData}
                        onClick={() => handleStatusChange(constClass.STATUS.REG)}>
                        登録済みに変更
                      </button>
                    </div>
                  }
                  {customerData.status === constClass.STATUS.UPD &&
                    <div className="col-3">
                      <button type="button"
                        className={`btn btn-primary mx-1 w-100`}
                        disabled={checkInvalid() || changed || lockData}
                        onClick={() => handleStatusChange(constClass.STATUS.REG)}>
                        更新申込
                      </button>
                    </div>
                  }
                </div>
                {!!id &&
                  <div className="row my-2">
                    <div className="col-3">
                      <div className="row px-0 mx-0">
                        <div className="col-3 align-self-center">
                          <input type="checkbox" className="form-control" id={'revert'} name={'revert'} value="true" checked={revert} onChange={() => setRevert(!revert)}></input>
                        </div>
                        <div className="col-9 align-self-center">
                          <label className="my-0" htmlFor={'revert'}>取消・退会処理</label>
                        </div>
                      </div>
                    </div>
                    {revert &&
                      <React.Fragment>
                        {customerData.status === constClass.STATUS.REG &&
                          <div className="col-3">
                            <button type="button"
                              className={`btn btn-primary mx-1 w-100`}
                              disabled={checkInvalid() || changed || lockData}
                              onClick={() => handleStatusChange(constClass.STATUS.UPD)}>
                              更新申込の取消
                            </button>
                          </div>
                        }
                        {[constClass.STATUS.REG, constClass.STATUS.UPD].includes(customerData.status) &&
                          <div className="col-3">
                            <button type="button"
                              className={`btn btn-primary mx-1 w-100`}
                              disabled={checkInvalid() || changed || lockData}
                              onClick={() => handleStatusChange(constClass.STATUS.CCL)}>
                              退会
                            </button>
                          </div>
                        }
                        {customerData.status === constClass.STATUS.CCL &&
                          <div className="col-3">
                            <button type="button"
                              className={`btn btn-primary mx-1 w-100`}
                              disabled={checkInvalid() || changed || lockData}
                              onClick={() => handleStatusChange(constClass.STATUS.REG)}>
                              退会の取消
                            </button>
                          </div>
                        }
                      </React.Fragment>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CustomerDetail;