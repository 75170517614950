import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import InputComponent from './InputComponent';

const User = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer, membership_history }) => {
  const history = useHistory();

  const checkInvalid = useCallback(() => {
    return false;
  }, []);

  const submit = useCallback(async () => {
    history.push(`/${siteId}/?page=${constClass.USERCHANGE}`);
  }, [history, siteId]);

  useEffect(() => {
    setBack({
      title: '戻る',
      render: true,
      disabled: false,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '変更',
      render: true,
      disabled: false,
      onClick: submit
    });
  }, [history, setBack, setNext, siteId, checkInvalid, submit])

  return (
    <div id="user" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          {(!!membership_history && membership_history.length > 0) &&
            <React.Fragment>
              <div className="input-label row mx-0 px-3-env pt-2">
                <div className="col px-0 font-weight-bold">
                  <span>更新履歴</span>
                </div>
              </div>
              <div className="input-body form-group">
                <div className="input-control row mx-0 px-3-env text-left">
                  <div className={`col px-3 py-1 align-self-center border`}>{Common.getBrString(Common.createPaymentHistory(membership_history))}</div>
                </div>
              </div>
            </React.Fragment>
          }
          {(setting['USER_HEADER']) &&
            <div className="row mx-0 pt-2 pb-1 text-left">
              <div className="col py-0 px-3 align-self-center">
                {Common.getBrString(setting['USER_HEADER'])}
              </div>
            </div>
          }
          <div className="customer-input row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center">
              {InputComponent.renderLabel('会員番号', my_customer.customer_no)}
              {InputComponent.renderLabel('お名前', my_customer.customer_name)}
              {InputComponent.renderLabel('お名前(カナ)', my_customer.customer_kana)}
              {InputComponent.renderLabel('携帯番号', my_customer.customer_tel)}
              {InputComponent.renderLabel('メールアドレス', my_customer.customer_mail)}
              {InputComponent.renderLabel('ご住所', `〒${my_customer.customer_postal_code}\n${my_customer.customer_address}`)}
              {/* {InputComponent.renderLabel('お誕生日', Common.getDateObject(my_customer.customer_birthday).str)} */}
              {/* {InputComponent.renderLabel('職種', my_customer.customer_job)} */}
              {/* {InputComponent.renderLabel('返金', `返金${constClass.REFUND_FLAG_NAME[my_customer.refund_flag]}`)} */}
              {InputComponent.renderLabel('返金口座', Common.getAccountString(my_customer))}
              {(setting['REFUND_NOTICE']) &&
                <div className="row mx-0 pt-2 pb-1 text-left">
                  <div className="col py-0 px-3 align-self-center">
                    {Common.getBrString(setting['REFUND_NOTICE'])}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default User;